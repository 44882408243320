<template>
  <div class="mapDetails">
    <gmap-map
      :center="center"
      :options="options"
      :zoom="zoom"
      :style="
        !isMobile ? 'width: 100%; height: 75vh;' : 'width: 100%; height: 30vh;'
      "
    >
      <gmap-marker
        v-if="!isGlobal"
        :position="center"
        @click="showInfoWindow = true"
      />
      <gmap-info-window
        v-if="!isGlobal"
        class="mapDetails-info"
        :position="center"
        :opened="showInfoWindow"
        @closeclick="showInfoWindow = false"
      >
        <div class="mapDetails-info">
          <div class="mapDetails-info-latitude">
            <p class="mapDetails-info-latitude-title">Latitude</p>
            <p class="mapDetails-info-latitude-value">
              {{ formatCoordinate(center.lat) }}
            </p>
          </div>
          <div class="mapDetails-info-longitude">
            <p class="mapDetails-info-longitude-title">Longitude</p>
            <p class="mapDetails-info-longitude-value">
              {{ formatCoordinate(center.lng) }}
            </p>
          </div>
          <a
            v-bind:href="
              'https://maps.google.com/?q=' + center.lat + ',' + center.lng
            "
            target="_blank"
            ><p class="mapDetails-info-googleMaps">Google Maps</p></a
          >
        </div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>
<script>
export default {
  data() {
    return {
      center: {
        lat: 0,
        lng: 0,
      }, // default to global coordinates
      isGlobal: true,
      options: {
        streetViewControl: false,
        scrollwheel: false,
        fullscreenControl: false,
        mapTypeControl: false,
      },
      showInfoWindow: false,
      isMobile: false,
      zoom: 2, // set the minimum zoom
    };
  },
  computed: {
    latLng() {
      return [
        this.$store.state.detailWaterPoint.latitude,
        this.$store.state.detailWaterPoint.longitude,
      ];
    },
  },
  methods: {
    formatCoordinate(value) {
      return value.toFixed(3);
    },
    updateMap() {
      const latitude = Number(this.$store.state.detailWaterPoint.latitude);
      const longitude = Number(this.$store.state.detailWaterPoint.longitude);
      // prevent (0,0) (NaN, NaN) and (null, null)
      if (latitude && longitude) {
        this.isGlobal = false;
        this.center = { lat: latitude, lng: longitude };
        this.zoom = 11; // set the zoom for non-global map
      }
    },
  },
  watch: {
    latLng() {
      this.updateMap();
    },
  },
  mounted() {
    this.updateMap();
    this.isMobile = window.innerWidth <= 500;
  },
};
</script>

<style scoped lang="scss">
.mapDetails {
  &-info {
    display: flex;
    align-items: center;
    width: 22em;
    justify-content: center;
    gap: 20px;
    &-latitude,
    &-longitude {
      display: flex;
      flex-direction: column;
      gap: 0px;
      &-title {
        color: #999a9d;
        font-weight: 700;
        margin-bottom: 0px;
      }
      &-value {
        font-weight: 700;
        margin-top: 5px;
      }
    }
    &-googleMaps {
      color: #0c3c6d;
      text-decoration: underline;
      font-weight: 700;
    }
  }
}
</style>
